import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { GetDocuments } from "./GetDocuments";
import { useAptApi } from "../../hooks/UseAptApi";
import { EngagementsLayout } from "./EngagementsLayout";
import Loading from "../misc/Loading";
import { useAsyncError } from "../../hooks/useAsyncError";
import { ApiError } from "../errors/CustomErrors";
import "./Landing.css";

export const AptEngagements = () => {
  const [engagements, setEngagements] = useState([]);
  const [item, setItem] = useState({ engagementId: "" });
  const [formSubmit, setformSubmit] = useState(false);
  const { engagementId } = item;
  const { GetEngagements } = useAptApi();
  const [loading, SetLoading] = useState(false);
  const throwError = useAsyncError();

  
  const handleChange = (e) => {
    e.persist();
    console.log(e.target.value);

    setItem((prevState) => ({
      ...prevState,
      engagementId: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformSubmit(!formSubmit);
  };

  useEffect(() => {
    SetLoading(true)
    async function LoadEngagements() {
      try {
        let response = await GetEngagements();

        if (response.isSuccess) {
          setEngagements(response.data.engagements);
          SetLoading(false);
        } else {
          throwError(
            new ApiError({
              apiMessage: response.message || response.data.message,
              statusCode: response.statusCode,
            })
          );
        }
      } catch (ex) {
        throwError(ex);
      }
    }

    LoadEngagements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!formSubmit ? (
        <>
          <EngagementsLayout />
          {!loading ? (
            <form
              onSubmit={handleSubmit}
              className="main form"
              id="engagementId"
            >
              {engagements.map((engagement) => (
                <div className="item" key={engagement.engagementId}>
                  <Form.Check key={engagement.engagementId}
                    value={engagement.engagementId}
                    type="radio"
                    aria-label="radio 1"
                    label={engagement.entityNameStateDate}
                    onChange={handleChange}
                    checked={engagementId === engagement.engagementId}
                  />
                </div>
              ))}
              {engagements.length === 0 && (
                <h3>There are no active engagements assigned to you.</h3>
              )}
              <div className="submitButtonContainer">
                <Button
                  className="bdoPrimaryBtn"
                  variant="primary"
                  type="submit"
                  disabled={engagements.length === 0 || !engagementId}
                >
                  Submit
                </Button>
              </div>
            </form>
          ): <Loading text="Loading Engagements" />}
          
        </>
      ) : (
        <GetDocuments engagmentId={engagementId} />
      )}
    </>
  );
};
