import React from "react";
import "./Landing.css"
export const EngagementsLayout = () => {

    return (
        <div className="aptLayout">
            <h2>Engagements</h2>
            <div>
              <p>
                This tool provides basic checks of the Excel (xlsx) and Word
                (docx) files that are contained within a specified APT
                engagement as a quality check to point you to where to look for
                potential errors within your documents.
              </p>
              <p>
                The checks that are currently included in this version of the
                tool are limited to:
              </p>
              <h5>
                Excel (xlsx)
              </h5>
              <ul>
                <li>​Yellow and red highlighted cells</li>
                <li>Formula errors</li>
                <li>Comments/Notes are present</li>
                <li>Hidden sheets</li>
                <li>Contained embedded Datasnipper file(s)</li>
                <li>References within a formula to other excel workbooks</li>                
              </ul>
              <h5>
                Word (docx)
              </h5>
              <ul>
                <li>Track Changes is enabled</li>
                <li>​Yellow and red highlighted text</li>
                <li>Comments are present</li>
              </ul>
              <p>
                Guidance of each of these checks and how best to apply the results to your documents can be found on <a target="_blank" href="https://insite.bdo.co.uk/sites/audit/auditresources/audittools/Pages/apt-document-checks.aspx">Insite</a> and in <a target="_blank" href="https://insite.bdo.co.uk/sites/audit/Documents/APT%20Document%20Checks%20-%20applying%20the%20results.docx">this guide</a>.                
              </p>
              <p>
                These checks can be run on any APT workspace and at any time
                during the audit. This tool may prove most useful when run when
                nearing the end of execution and/or during completion.
              </p>
              <p>
                Select the APT workspace from the list below and click{" "}
                <strong>SUBMIT</strong>.
              </p>
              <p>
                A report will be sent to you via email, please allow up to 20 minutes(*) for it to arrive. This report is in Excel and contains a separate guidance tab to explain the results and how to apply them to your APT workspace.
                <br />
                <small>
                  <i>(*) APT Document Checks may take longer to scan engagements with large Excel/Word files (~20MB to 60MB) with the report taking a few hours to arrive. For very large Excel/Word files (>60MB) these may not scan, and will show as 'failure to process' errors within the report - you should complete your usual checks manually for any such files.</i>
                </small>
              </p>
            </div>
          </div>
    )
}