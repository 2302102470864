import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';


const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: true, // for react router v6
        namePrefix: 'apt-document-checks-ai',
        // @ts-ignore
        extensions: [reactPlugin],
        disableCookiesUsage: true
    }
});

appInsights.loadAppInsights();


export { reactPlugin as appInsightsReactPlugin, appInsights as appInsightsInstance }
export const ai = appInsights