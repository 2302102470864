import React from 'react'

const Warning = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.52 26.69" fill="var(--red)">
        <path d="M29.69,26.64H.88a.89.89,0,0,1-.76-.44.85.85,0,0,1,0-.87L14.52.38A.91.91,0,0,1,15.79.17,1,1,0,0,1,16,.38l14.41,25a.88.88,0,0,1-.76,1.31ZM2.39,24.89H28.17L15.28,2.56Zm14.39-8V7.41h-3V19Zm-3,4.24v2.3h3V19Z" />
    </svg>
  )
}

export default Warning