import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from 'react-bootstrap/Button';
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {

        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });

    }

    return (
        <Button variant="secondary" className='ml-auto bdoSecondaryBtn' onClick={() => handleLogout(instance)}>Sign out</Button>
    )
}