import React from "react";

export default function Error({
  title = "Error",
  message = "There was an error processing your request, please try again.",
  ...props
}) {
  return (
    <>
      <h3>{title}</h3>
      <p>{message}</p>
      {props.children}
    </>
  );
}
