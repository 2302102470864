import React, { Fragment } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ai } from "../insights/AppInsights";
import { ErrorPage } from "./ErrorPage";
import { ApiError } from "./CustomErrors";

export default class ErrorBoundaryInsights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: undefined,
      redirect: false,
      unAuthorized: false,
      apiCode: undefined,
      apiMessage: undefined,
      WebAppError: false,
      message: undefined,
      statusCode: undefined,
    };
  }  

  componentDidCatch( issue, errorInfo ) {
    this.setState({
      hasError: true,
      errorInfo: errorInfo,
      statusCode: issue.statusCode,
      apiMessage: issue.apiMessage,
      apiCode: issue.apiCode,
      message: issue.message,
    });

    if (issue instanceof ApiError) {      
      // if (issue instanceof WebAppError) {
      //   //We should log all WebAppError.
      //   ai.appInsights.trackException({
      //     error: issue,
      //     exception: issue,
      //     severityLevel: SeverityLevel.Error,
      //     properties: { ...errorInfo },
      //   });
      // }
      ai.appInsights.trackException({
        error: issue,
        exception: issue,
        severityLevel: SeverityLevel.Error,
        properties: { ...errorInfo },
      });
      this.calculateState(issue);
    } else {
      ai.appInsights.trackException({
        error: issue,
        exception: issue,
        severityLevel: SeverityLevel.Error,
        properties: { ...errorInfo },
      });
    }    
  }

  calculateState = (issue) => {
    let unAuthorized = false;
    let WebAppError = false;
    switch (issue.statusCode) {
      case 0:
        WebAppError = true;
        break;
      case 403:
        unAuthorized = true;
        break;
      // case 404:
      //   break;
      case 401:
        unAuthorized = true;
        break;
      // case 500:
      //   break;
      default:
        break;
    }

    this.setCurrentState(unAuthorized, WebAppError);
  };

  setCurrentState = (unAuthorized, WebAppError) => {
    this.setState((prev) => ({
      ...prev,
      unAuthorized: unAuthorized,
      WebAppError: WebAppError,
    }));
  };

  renderMessage = () => {
    let message = (
      <Fragment>
        {(this.state.apiCode || this.state.statusCode) && <p> Error: {this.state.message || this.state.apiMessage}</p>}        
      </Fragment>
    );

    if (this.state.unAuthorized) {
      message = (
        <Fragment>
          <h4>You don{"'"}t have access.</h4>
          <br />
          {this.state.statusCode && <p> Error: {this.state.apiMessage}</p>} 
        </Fragment>
      );
    }

    return message;
  };

  render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <ErrorPage
            message={this.renderMessage()} />
        </Fragment>
      );
    }    
    return this.props.children;
  }
}