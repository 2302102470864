import React from "react";
import "./styles/App.css";
import { PageLayout } from "./components/layout/PageLayout";
import { AptEngagements } from "./components/landing/AptEngagements";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsightsReactPlugin } from "./components/insights/AppInsights";
import { UnhandledException } from "./components/errors/UnhandledException";
import AppAuth from "./components/auth/AppAuth";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import ErrorBoundaryInsights from "./components/errors/ErrorBoundaryInsights";

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <AppInsightsContext.Provider value={appInsightsReactPlugin}>
      <ErrorBoundaryInsights
        onError={() => <UnhandledException />}
        appInsights={appInsightsReactPlugin}
      >
        <MsalProvider instance={msalInstance}>
          <AppAuth>
            <div className="App">
              <PageLayout>
                <main role="main">
                  <AptEngagements />
                </main>
              </PageLayout>
            </div>
          </AppAuth>
        </MsalProvider>
      </ErrorBoundaryInsights>
    </AppInsightsContext.Provider>
  );
}
export default App;
