export class ApiError extends Error {    
    apiCode 
    apiMessage
    statusCode

    constructor({
        apiCode = undefined, 
        apiMessage,
        statusCode
    })
    { super();
        this.apiCode = apiCode;
        this.apiMessage = apiMessage;
        this.statusCode = statusCode;
    }
}

export class WebAppError extends Error {    
    message
    statusCode
    constructor({
        message, 
        statusCode
    })
    { super();
        this.message = message;
        this.statusCode = statusCode;
    }
}