import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./misc.css"

const Loading = (props) => {
  const { text } = props;

  return (
    <div className="loadingContainer">
      <Spinner/>
      <h2>{text}</h2>
    </div>  
  );
};

export default Loading;
