import React, { useState, useEffect } from "react";
import Loading from "../misc/Loading";
import StatusPage from "../layout/StatusPage";
import { useAptApi } from "../../hooks/UseAptApi";
import { useAsyncError } from "../../hooks/useAsyncError";
import { ApiError } from "../errors/CustomErrors";

export const GetDocuments = ({ engagmentId }) => {
  const [responseData, setresponseData] = useState();
  const { GetDocuments } = useAptApi();  
  const throwError = useAsyncError();

  useEffect(() => {
    async function GetDocumentsByEngagementId() {
      try {
        let response = await GetDocuments(engagmentId);

        if (response.isSuccess) {
          setresponseData(response.data);
        } else {
          throwError(
            new ApiError({
              apiCode: response.data.apiCode,
              apiMessage: response.data.message,
              statusCode: response.statusCode,
            })
          );
        }
      } catch (ex) {
        throwError(ex);
      }
    }
    GetDocumentsByEngagementId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {responseData ? (
        <StatusPage
          // @ts-ignore
          status={responseData.response.success}
          // @ts-ignore
          message={responseData.response.message}
        />
      ) : (
        <Loading text="Getting Documents" />
      )}
    </div>
  );
};
