import Warning from "components/icons/Warning";
import { PageLayout } from "../layout/PageLayout";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Errors.css";


export const ErrorPage = ({
  message,
}) => {

  return (
    <PageLayout>
    <div className="errorPageContainer">
      <div className="iconContainer">
        <Warning />
      </div>

      <h1>
        Oops! Something's not right...
      </h1>

      <p className="infoText">
        please contact{" "}
        <a
          href="mailto:it.servicedesk@bdo.co.uk"
        >
          it.servicedesk@bdo.co.uk
        </a>
      </p>

      <Accordion>
        <Accordion.Item className="accordionItem" eventKey="0">
          <Accordion.Header>Technical details</Accordion.Header>
          <Accordion.Body className="accordionBody">
            {message}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  </PageLayout>
  );
};
