import React, { useState, useEffect } from "react";
import Warning from "../icons/Warning";
import Success from "../icons/Success";
import "./Layout.css";

const StatusPage = ({ status, message, ...props}) => {
  const [countdown, setCountdown] = useState(5);

  const status200 = status === true;

  useEffect(() => {
    if (countdown === 0) {
      // @ts-ignore
      window.location.reload(false);
      return;
    }

    const interval = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [status200, countdown]);

  return (
    <div className="statusPageContainer">
      <div className="iconContainer">
        {status200 ? <Success/> : <Warning/>}
      </div>

      <h1>
        {status200 ? "Success" : "Error"}
      </h1>

      <p>
        {status200
          ? `Your request was successful, you will be redirected in ${countdown}`
          : message}
      </p>
    </div>
  );
};

export default StatusPage;
