import React from "react";

const Footer = () => {
	return (
		<footer>
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.51 123.16">
					<defs></defs>
					<path
						id="header"
						fill="#333"
						d="M226.76 78.12a30.33 30.33 0 1 1 2.07-60.59l6.88-9.83-3.3-2.31L236.19 0l8.69 6.09L232 24.52l-2-.26a23.75 23.75 0 1 0 17.85 12.5l-.94-1.76 12.9-18.44 8.7 6.09-3.78 5.35-3.3-2.32-6.88 9.83a30.33 30.33 0 0 1-27.79 42.58ZM0 18.09h13.77v47.55h20.28v11.58H0Zm61.25-.27h11.31l24.5 59.4H82.34l-5.65-14.09H57.48L52.1 77.22H38Zm11.31 34.37L69.78 44c-1.35-4-2.06-6.55-2.51-8.44h-.18a67.06 67.06 0 0 1-2.79 8.53l-3 8.08Zm28.96-34.1h19.83c12.83 0 18.57 7.53 18.57 15a13.46 13.46 0 0 1-6.73 11.76V45a14.17 14.17 0 0 1 10.86 14.17c0 8.71-7 18-21.18 18h-21.35Zm17.85 23.69c4.76 0 7.18-2.88 7.18-6.11 0-3.5-2.42-6.37-7.54-6.37h-3.76v12.48Zm1.53 24.31c6.55 0 9.42-2.24 9.42-7 0-3.94-3-6.19-8.16-6.19h-6.91v13.19Zm29.29-3.85a23.87 23.87 0 0 0 13.64 4.48c4 0 8.44-1.35 8.44-5.2 0-9.78-25.12-8.53-25.12-26.74 0-9.07 7.26-17.32 20.36-17.32a35.81 35.81 0 0 1 15.62 3.23V33c-5.48-2.69-10.32-4.13-15.17-4.13-3.32 0-6.19 1.17-6.19 4.76 0 9.15 25.13 7.72 25.13 26.74 0 10.95-9.87 17.77-22.53 17.77-7 0-14.26-1.89-19.38-5.57Z"
					/>
					<g id="bubbles">
						<circle cx="233.11" cy="33.96" r="4.98" className="paleEmerald" />
						<circle cx="242.2" cy="25.78" r="3.2" className="paleEmerald" />
						<path fill="#218f8b" d="M209.68 42.56a17.91 17.91 0 1 0 34.24 0Z" />
					</g>
					<g className="subheader">
						<path
							xmlns="http://www.w3.org/2000/svg"
							id="subheadAudit"
							fill="#9f9f9f"
							d="M11.29 93h3l11.52 29.74h-4.35l-2.88-7.78H7.06l-2.83 7.78H0Zm6 18.67-2.79-7.74-1.66-5h-.09q-.31 1.13-1.71 5l-2.79 7.69ZM30.82 117a14.44 14.44 0 0 1-.82-5V93.06h4v18.58a10.17 10.17 0 0 0 .67 4q1.62 4.05 6.75 4t6.8-4a11.46 11.46 0 0 0 .63-4.18v-18.4h4V112a13 13 0 0 1-.9 5.17q-2.38 6-10.53 6T30.82 117Zm29.39-23.94H68q7.7 0 11.79 4t4.1 10.67a14.51 14.51 0 0 1-4.21 10.84q-4.2 4.14-12 4.14h-7.47Zm7.51 26.23q6.21 0 9.07-3.21a12.23 12.23 0 0 0 2.86-8.44 11 11 0 0 0-3-8.15q-3-3-8.93-3h-3.51v22.81ZM90 93.06h4v29.65h-4Zm17.19 3.42h-8.55v-3.42h22l-1.22 3.42h-8.19v26.23h-4Z"
						/>
					</g>
				</svg>
			</div>
		</footer>
	);
};

export default Footer;
